import React, {useState} from 'react';
import { calculateWinner } from '../helpers';
import Board from './Board';

/* first way */
const Game = () => {
	const [board, setBoard] = useState(Array(9).fill(null));
	const [xIsNext, setXisNext] = useState(true);
	const winner = calculateWinner(board);
	
	const handleClick = i => {
        const boardCopy = [...board];
		// If user click an occupied square or if game is won
        if (winner || boardCopy[i]) return;
        // Put an X or an O in the clicked square
        boardCopy[i] = xIsNext ? 'X' : 'O';
        setBoard(boardCopy);
        setXisNext(!xIsNext);
    }
    const startGame = () => (
	// press button to empty the board
        <button onClick={() => setBoard(Array(9).fill(null))}>
            {winner ? 'Start New Game' : 'Start Game'}
        </button>
    )
	return(
		<div className="game">
			<div className="title">
				<h1>Tic Tac Toe Game</h1>
			</div>
			
			<Board squares={board} onClick={handleClick} />
			
			<div className="game-info">
				<p>{winner ? <span className="result">The Winner is: {winner} </span> : 
					'Next Player: ' + (xIsNext ? 'X' : 'O')}</p>
				{startGame()}
			</div>
			<div className="footer">
				<p>by: Doriana Zela</p>
			</div>
		</div>
	)
}

export default Game;


/* second way 

const Game = () => {
    const [history, setHistory] = useState([Array(9).fill(null)]);
    const [stepNumber, setStepNumber] = useState(0);
    const [xIsNext, setXisNext] = useState(true);
    const winner = calculateWinner(history[stepNumber]);

    const handleClick = i => {
        const timeInHistory = history.slice(0, stepNumber + 1);
        const current = timeInHistory[stepNumber];
        const squares = [...current];
        // If user click an occupied square or if game is won, return
        if (winner || squares[i]) return;
        // Put an X or an O in the clicked square
        squares[i] = xIsNext ? 'X' : 'O';
        setHistory([...timeInHistory, squares]);
        setStepNumber(timeInHistory.length);        
        setXisNext(!xIsNext);
    }

    const jumpTo = step => {
        setStepNumber(step);
        setXisNext(step % 2 === 0)
    };

    const renderMoves = () => (
        history.map((_step, move) => {
            const destination = move ? `Go to move#${move}` : 'Go to start';
            return (
                <li key={move}>
                    <button onClick={() => jumpTo(move)}>{destination}</button>
                </li>
            )
        })        
    )

    return (
        <div>
			<h1>Tic Tac Toe Game</h1>
            <Board squares={history[stepNumber]} onClick={handleClick} />
            <div style={styles}>
                <p>{winner ? 'Winner: ' + winner : 'Next Player: ' + (xIsNext ? 'X' : 'O')}</p>
                {renderMoves()}
            </div>
        </div>
    )
}
*/
import React from 'react';
import './App.css';

import Game from './components/Game';

function App() {
  return (
      <Game />
  );
}

export default App;
